<template>
  <div id="galerie" class="container">
    <h1 class="mt-4 mb-3">Galerie</h1>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link to="/">Accueil</router-link>
      </li>
      <li class="breadcrumb-item active">Galerie</li>
    </ol>
    <div class="row marg-top-row-1">
      <div class="col-lg-4 col-sm-6 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/05-thumb.jpg" full="/assets/05.jpg" altname="Main courante" titlename="Main courante"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Main courante</h3>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/06-thumb.jpg" full="/assets/06.jpg" altname="Rampe" titlename="Rampe"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Rampe</h3>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/07-thumb.jpg" full="/assets/07.jpg" altname="Portail" titlename="Portail"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Portail</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/09-thumb.jpg" full="/assets/09.jpg" altname="Décoration" titlename="Décoration"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Décoration</h3>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/10-thumb.jpg" full="/assets/10.jpg" altname="temp" titlename="temp"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Garde Corps</h3>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/11-thumb.jpg" full="/assets/11.jpg" altname="Véranda" titlename="Véranda"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Véranda</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/12-thumb.jpg" full="/assets/12.jpg" altname="temp" titlename="temp"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Meuble</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/13-thumb.jpg" full="/assets/13.jpg" altname="temp" titlename="temp"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Verriere</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/14-thumb.jpg" full="/assets/14.jpg" altname="Porte" titlename="Porte"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Marquise</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/15-thumb.jpg" full="/assets/15.jpg" altname="Portail" titlename="Portail"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Portail coulissant</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/16-thumb.jpg" full="/assets/16.jpg" altname="Véranda" titlename="Véranda"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Véranda</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/17-thumb.jpg" full="/assets/17.jpg" altname="temp" titlename="temp"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Marquise</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/18-thumb.jpg" full="/assets/18.jpg" altname="temp" titlename="temp"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Véranda</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/19-thumb.jpg" full="/assets/19.jpg" altname="Portail" titlename="Portail"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Portail</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/20-thumb.jpg" full="/assets/20.jpg" altname="temp" titlename="temp"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Table</h3>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="row marg-top-row-1 my-marg">
      <div class="col-lg-3 col-sm-4 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/99-thumb.jpg" full="/assets/99.jpg" altname="Rampe" titlename="Rampe"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Rampe</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-4 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/98-thumb.jpg" full="/assets/98.jpg" altname="temp" titlename="temp"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Grille décorative</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-4 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/97-thumb.jpg" full="/assets/97.jpg" altname="temp" titlename="temp"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Décoration</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-4 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/96-thumb.jpg" full="/assets/96.jpg" altname="temp" titlename="temp"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Porte coulissante</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-4 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/95-thumb.jpg" full="/assets/95.pg" altname="Rampe" titlename="Rampe"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Rampe</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-4 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/94-thumb.jpg" full="/assets/94.jpg" altname="Porte"  titlename="Porte"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Grille</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-4 portfolio-item">
        <div class="card h-100">
          <image-dialog thumb="/assets/93-thumb.jpg" full="/assets/93.jpg" altname="Escalier"  titlename="Escalier"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Escalier</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-4 portfolio-item">
        <div class="card h-100">
          <!-- <img class="card-img-top" src="/assets/92.jpg" alt> -->
          <image-dialog thumb="/assets/92-thumb.jpg" full="/assets/92.jpg" altname="temp" titlename="temp"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Meuble</h3>
          </div>
        </div>
      </div>
      
      <div class="col-lg-3 col-sm-4 portfolio-item">
        <div class="card h-100">
          <!-- <img class="card-img-top" src="/assets/92.jpg" alt> -->
          <image-dialog thumb="/assets/91-thumb.jpg" full="/assets/91.jpg" altname="temp" titlename="portail"></image-dialog>
          <div class="card-body">
            <h3 class="card-title">Portail</h3>
          </div>
        </div>
      </div>
      
    </div>

  </div>
</template>

<script>
import ImageDialog from '../components/ImageDialog.vue';

export default {
    components: { ImageDialog },
}
</script>


<style scoped>
div.card-body{
    padding: 0.8rem;
}
 h3.card-title{
     font-size: 1.17em;
     margin-bottom: 0;
 }
 .my-marg{
     margin-top: 2rem;
 }
</style>